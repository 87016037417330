import {
  IconButton,
  Button,
  TableCell,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import React from "react"
import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  margin: 0 0 30px 0;
`

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40vh 0;
`

export const BoldText = styled.span`
  font-weight: bold;
`

export const Header = styled.div`
  margin-bottom: 30px;
`

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  @media screen and (min-width: 800px) {
    margin: 1rem 0 1rem 0;
  }
`

export const HeaderTitleText = styled.h1`
  margin: 0;
`

export const HeaderTitleProgress = styled.span<{ progressPercents?: number }>`
  font-size: 2em;
  font-weight: bold;
  color: #429b00;
  margin: 0 0 0 20px;
  ${props =>
    props.progressPercents !== undefined &&
    css`
      color: ${props.progressPercents >= 100 ? "#429b00" : "unset"};
    `}
`

export const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
`
export const HeaderActionsLeft = styled.div``
export const HeaderActionsRight = styled.div``

export const EmptyHeader = styled.h2`
  text-align: center;
`

export const TableCardContent = styled(props => <CardContent {...props} />)`
  padding: 0;
  :last-child {
    padding-bottom: 0;
  }
`

export const MaterialUrl = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  &:link,
  &:visited {
    color: inherit;
  }
  svg {
    color: ${props => props.theme.palette.primary.dark};
    margin-right: 10px;
  }
`

export const TableCellCheckboxHeader = styled(props => (
  <TableCell {...props} />
))`
  text-align: right;
`
export const TableCellCheckbox = styled(props => <TableCell {...props} />)`
  padding: 0 16px;
  text-align: right;
`

export const CheckboxWrapper = styled.div<{
  checked: boolean
}>`
  color: ${props => (props.checked ? "#429b00" : "inherit")};
`

export const StyledFormControlLabel = styled(props => (
  <FormControlLabel {...props} />
))`
  color: #429b00;
`

export const StyledCheckbox = styled(props => <Checkbox {...props} />)`
  &.Mui-checked :not(.Mui-disabled) {
    color: #429b00;
  }
`

export const RemoveButton = styled(props => <Button {...props} />)`
  color: #ff3636;
`
