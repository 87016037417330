import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import React from "react"
import { ThemeProvider } from "styled-components"

import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import { Footer } from "../../../molecules/Footer"
import { BaseToolbar } from "../../../organisms/BaseToolbar"
import * as S from "./style"

interface PlanResourceLayoutProps {}

const PlanResourceLayout: React.FC<PlanResourceLayoutProps> = ({
  children,
}) => {
  const intl = useIntl()

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <BaseToolbar
          backText={intl.formatMessage({
            defaultMessage: "Learning Plan",
            description: "back link label",
          })}
          backLink="/"
        />
        <S.Content>{children}</S.Content>
        <S.Footer>
          <Footer />
        </S.Footer>
      </S.Wrapper>
    </ThemeProvider>
  )
}

export { PlanResourceLayout }
