import { useQuery } from "@apollo/client"
import { Router } from "@reach/router"
import { useIntl } from "gatsby-plugin-react-intl"
import React, { useContext, useState } from "react"
import { SEO } from "../components/atoms/SEO"
import { BaseDrawer } from "../components/organisms/BaseDrawer"
import { userQuery } from "../components/sets/auth/LoginPage/queries"
import { PlanResourcePage } from "../components/sets/user/PlanResourcePage"
import { userResourceListQuery } from "../components/sets/user/queries"
import { AuthContext } from "../containers/AuthContext"
import { useIntlPathPrefix } from "../hooks/useIntlPathPrefix"

interface Organization {
  id: number
  name: string
}

interface OrganizationSet {
  organization: Organization
}

interface EducationMaterialKind {
  id: number
  name: string
}

interface EducationMaterial {
  id: number
  name: string
  slug: string
  websiteUrl: string
  kind: EducationMaterialKind
}

interface Course {
  id: number
  name: string
  slug: string
  description: string
  websiteUrl: string
  educationMaterials: EducationMaterial[]
  courseorganizationSet: OrganizationSet[]
}

interface UserCourseRegistration {
  id: number
  code: string
  cost: number
  costCurrency: string
  course: Course
}

interface Data {
  userCourseRegistrationList: UserCourseRegistration[]
}

const Plan: React.FC = () => {
  const pathPrefix = useIntlPathPrefix()
  const intl = useIntl()

  const { loading: authLoading, authenticated } = useContext(AuthContext)
  const user = useQuery(userQuery, {
    fetchPolicy: "cache-first",
    skip: !authenticated,
  })
  const userResourceList = useQuery<Data>(userResourceListQuery, {
    fetchPolicy: "cache-and-network",
    skip: !authenticated,
  })

  const userResourceRegistrationList =
    userResourceList?.data?.userCourseRegistrationList

  return (
    <>
      <Router basepath={`${pathPrefix}/plan`}>
        {userResourceRegistrationList?.map(({ id }) => (
          <PlanResourcePage key={id} path={`${id}`} id={id} />
        ))}
      </Router>
    </>
  )
}

export default Plan
