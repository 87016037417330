import { useMutation, useQuery } from "@apollo/client"
import { RouteComponentProps, useLocation } from "@reach/router"
import queryString from "query-string"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-react-intl"
import React, { useContext, useState } from "react"
import { BaseDrawer } from "../../../organisms/BaseDrawer"
import { PlanResourceLayout } from "../PlanResourceLayout"
import { userResourceQuery } from "../queries"
import { PlanResourcePageContent } from "./PlanResourcePageContent"
import { CircularProgress } from "@mui/material"

import * as S from "./style"
import { AuthContext } from "../../../../containers/AuthContext"
import { useIntlPathName } from "../../../../hooks/useIntlPathName"
import { HomeLayout, Tab } from "../../home/HomeLayout"
import {
  removeUserCourseRegistrationMutation,
  setEducationMaterialProgressPassedMutation,
} from "../mutations"
import { ResourceRemoveDialog } from "./ResourceRemoveDialog"
import { SEO } from "../../../atoms/SEO"

type DialogType = "remove"

interface Organization {
  id: number
  name: string
}

interface OrganizationSet {
  organization: Organization
}

interface EducationMaterialKind {
  id: number
  name: string
}

interface EducationMaterial {
  id: number
  name: string
  slug: string
  websiteUrl: string
  kind: EducationMaterialKind
}

interface CourseProgress {
  id: number
  passed: boolean
  examined: boolean
}

export interface EducationMaterialProgress {
  courseProgress?: CourseProgress | null
  educationMaterial: EducationMaterial
}

export interface Resource {
  id: number
  name: string
  slug: string
  description: string
  websiteUrl: string
  courseorganizationSet: OrganizationSet[]
}

interface UserCourseRegistration {
  id: number
  code: string
  cost: number
  costCurrency: string
  course: Resource
  educationMaterialProgressList: EducationMaterialProgress[]
}

interface Data {
  userCourseRegistration: UserCourseRegistration
}

interface PlanResourcePageProps extends RouteComponentProps {
  id: number
}

const PlanResourcePage: React.FC<PlanResourcePageProps> = ({ id }) => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = useIntlPathName()

  const { loading: authLoading, authenticated } = useContext(AuthContext)
  const userResource = useQuery<Data>(userResourceQuery, {
    fetchPolicy: "cache-and-network",
    skip: !authenticated,
    variables: {
      registrationId: id,
    },
  })
  const selectedResource = id && userResource.data?.userCourseRegistration
  const [removeUserCourseRegistration, removeUserCourseRegistrationOpts] =
    useMutation(removeUserCourseRegistrationMutation)
  const [
    setEducationMaterialProgressPassed,
    setEducationMaterialProgressPassedOpts,
  ] = useMutation(setEducationMaterialProgressPassedMutation)

  const [dialogOpened, setDialogOpened] = React.useState<DialogType>()

  const handleRemoveClick = async () => {
    if (authenticated) {
      await removeUserCourseRegistration({
        variables: {
          courseRegistrationId: id,
        },
      })
      navigate("/")
    } else {
      navigate("/")
    }
  }

  const handleMaterialPassClick = async (
    material: EducationMaterialProgress
  ) => {
    if (authenticated) {
      await setEducationMaterialProgressPassed({
        variables: {
          educationMaterialId: material.educationMaterial.id,
          courseRegistrationId: id,
          passed: !material.courseProgress?.passed,
        },
      })
      userResource.refetch()
    } else {
      navigate("/")
    }
  }

  return (
    <>
      <SEO subtitle={selectedResource?.course?.name} />
      <PlanResourceLayout>
        {authLoading ||
        (!userResource.data?.userCourseRegistration && userResource.loading) ? (
          <S.Loading>
            <CircularProgress color="primary" />
          </S.Loading>
        ) : (
          <>
            <PlanResourcePageContent
              resource={selectedResource?.course}
              educationMaterialProgressList={
                selectedResource?.educationMaterialProgressList
              }
              registrationCode={selectedResource?.code}
              registrationCost={
                selectedResource?.cost === 0
                  ? intl.formatMessage({
                      defaultMessage: "Free",
                      description: "option label",
                    })
                  : selectedResource?.costCurrency &&
                    selectedResource?.cost &&
                    Number(selectedResource.cost)?.toLocaleString(undefined, {
                      style: "currency",
                      currency: selectedResource?.costCurrency,
                    })
              }
              loading={userResource.loading}
              onRemoveClick={() => setDialogOpened("remove")}
              onMaterialPassClick={handleMaterialPassClick}
            />
            <ResourceRemoveDialog
              open={dialogOpened === "remove"}
              onSubmit={handleRemoveClick}
              onClose={() => setDialogOpened(undefined)}
            />
          </>
        )}
      </PlanResourceLayout>
    </>
  )
}
export { PlanResourcePage }
