import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
} from "@mui/material"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"
import { getProgressPercentsFromCourseRegistration } from "../../../../../utils/data"
import { ButtonLink } from "../../../atoms/ButtonLink"
import { EducationMaterialProgress, Resource } from "./PlanResourcePage"

import * as S from "./style"

interface PlanResourcePageContentProps {
  resource?: Resource
  educationMaterialProgressList?: EducationMaterialProgress[]
  registrationCode: string
  registrationCost: string
  loading?: boolean
  onRemoveClick: () => void
  onMaterialPassClick: (material: EducationMaterialProgress) => void
}

const PlanResourcePageContent: React.FC<PlanResourcePageContentProps> = ({
  resource,
  educationMaterialProgressList,
  registrationCode,
  registrationCost,
  loading,
  onRemoveClick,
  onMaterialPassClick,
}) => {
  const progressPercents = getProgressPercentsFromCourseRegistration(
    educationMaterialProgressList
  )

  return (
    <S.Wrapper>
      {!resource ? (
        <S.EmptyHeader>
          <FormattedMessage
            defaultMessage="You don't have any courses added yet. Check the available courses on the website."
            description="information"
          />
        </S.EmptyHeader>
      ) : (
        <S.Header>
          <S.HeaderTitle>
            <S.HeaderTitleText>{resource.name}</S.HeaderTitleText>
            <S.HeaderTitleProgress
              progressPercents={progressPercents}
            >{`${progressPercents}%`}</S.HeaderTitleProgress>
          </S.HeaderTitle>
          <p>
            <FormattedMessage defaultMessage="Author" description="label" />:{" "}
            <S.BoldText>
              {resource.courseorganizationSet
                .map(({ organization }) => organization.name)
                .join(", ")}
            </S.BoldText>
          </p>
          <p>{resource.description}</p>
          {/* <p>
            <FormattedMessage
              defaultMessage="Unique registration code"
              description="label"
            />
            : <S.BoldText>{registrationCode}</S.BoldText>
          </p> */}
          {/* <p>
            <FormattedMessage
              defaultMessage="Registration cost"
              description="label"
            />
            : <S.BoldText>{registrationCost}</S.BoldText>
          </p> */}
          <S.HeaderActions>
            <S.HeaderActionsLeft>
              <ButtonLink
                linkType="external"
                link={resource.websiteUrl}
                anchorProps={{
                  target: "_blank",
                  rel: "nofollow noopener",
                }}
                variant="contained"
                color="primary"
              >
                <FormattedMessage defaultMessage="Open" description="label" />
              </ButtonLink>
            </S.HeaderActionsLeft>
            <S.HeaderActionsRight>
              <ButtonLink link={`/resources/${resource.id}`}>
                <FormattedMessage
                  defaultMessage="Details"
                  description="label"
                />
              </ButtonLink>
              <Button onClick={onRemoveClick}>
                <FormattedMessage defaultMessage="Remove" description="label" />
              </Button>
            </S.HeaderActionsRight>
          </S.HeaderActions>
        </S.Header>
      )}
      {!educationMaterialProgressList ||
      educationMaterialProgressList.length <= 0 ? (
        <></>
      ) : (
        <Card>
          <S.TableCardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Material"
                      description="table header"
                    />
                  </TableCell>
                  <S.TableCellCheckboxHeader>
                    <FormattedMessage
                      defaultMessage="State"
                      description="table header"
                    />
                  </S.TableCellCheckboxHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {educationMaterialProgressList?.map(material => (
                  <TableRow key={material.educationMaterial.id}>
                    <TableCell component="th" scope="row">
                      <S.MaterialUrl
                        href={material.educationMaterial.websiteUrl}
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <OpenInNewIcon fontSize="small" />
                        {material.educationMaterial.name}
                      </S.MaterialUrl>
                    </TableCell>
                    <S.TableCellCheckbox component="th" scope="row">
                      <S.CheckboxWrapper
                        checked={material.courseProgress?.passed}
                      >
                        <FormControlLabel
                          control={<S.StyledCheckbox />}
                          checked={material.courseProgress?.passed}
                          disabled={loading}
                          label="Read"
                          labelPlacement="start"
                          onClick={() => onMaterialPassClick(material)}
                        />
                      </S.CheckboxWrapper>
                    </S.TableCellCheckbox>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </S.TableCardContent>
        </Card>
      )}
    </S.Wrapper>
  )
}

export { PlanResourcePageContent }
