import React from "react"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material"

import * as S from "./style"

interface ResourceRemoveDialogProps {
  open?: boolean
  onSubmit: () => void
  onClose: () => void
}

const ResourceRemoveDialog: React.FC<ResourceRemoveDialogProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage
            defaultMessage="This resource with its progress will be removed from your learning plan. Do you want to proceed?"
            description="dialog description"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage defaultMessage="Cancel" description="button" />
        </Button>
        <S.RemoveButton onClick={onSubmit} autoFocus>
          <FormattedMessage defaultMessage="Remove" description="button" />
        </S.RemoveButton>
      </DialogActions>
    </Dialog>
  )
}
export { ResourceRemoveDialog }
