import { IconButton, AppBar, Toolbar } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

export const Wrapper = styled.div``

export const HeaderSection = styled.section`
  padding: 30px 30px;

  @media screen and (min-width: 800px) {
    padding: 60px 80px;
  }
  @media screen and (min-width: 1200px) {
    padding: 60px 20%;
  }
`

export const StyledAppBar = styled(props => (
  <AppBar position="sticky" {...props} />
))`
  z-index: ${props => props.theme.zIndex.drawer + 1};
`

export const StyledToolbar = styled(props => <Toolbar {...props} />)`
  align-items: flex-start;
  align-items: center;
  margin: 0;
`

export const BackButton = styled(props => (
  <IconButton color="inherit" aria-label="open drawer" edge="start" {...props}>
    <ArrowBackIcon />
  </IconButton>
))``

export const Title = styled.h1`
  font-size: 1.6em;
  width: 100%;
`

export const User = styled.div`
  margin-left: auto;
`

export const Content = styled.div`
  min-height: 100%;
  padding: 0 24px;
  margin: 30px 0;
  display: grid;
  grid-gap: 24px;

  @media screen and (min-width: 800px) {
    margin: 30px 5%;
  }
  @media screen and (min-width: 1200px) {
    margin: 30px 10%;
  }

  /* max-width: 960px;
  display: grid;
  grid-gap: 20px;
  margin: 130px 0 30px 0;

  @media screen and (min-width: 800px) {
    margin: 120px 5% 30px 5%;
  }
  @media screen and (min-width: 1200px) {
    margin: 120px 10% 30px 10%;
  } */
`

export const MyCoursesList = styled.div<{ noCourses: boolean }>`
  ${props =>
    !props.noCourses &&
    `@media only screen and (min-width: 600px) {
      margin-left: 240px;
    }`}
`

export const Tray = styled.div`
  grid-area: tray;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
`

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
`

export const BreadcrumbsLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: #fff;
`

export const Footer = styled.div``
