import { gql } from "@apollo/client"

export const removeUserCourseRegistrationMutation = gql`
  mutation RemoveCourseRegistration($courseRegistrationId: Int) {
    removeUserCourseRegistration(courseRegistrationId: $courseRegistrationId) {
      removed
    }
  }
`

export const setEducationMaterialProgressPassedMutation = gql`
  mutation SetEducationMaterialProgressPassed(
    $educationMaterialId: Int
    $courseRegistrationId: Int
    $passed: Boolean
  ) {
    setEducationMaterialProgressPassed(
      educationMaterialId: $educationMaterialId
      courseRegistrationId: $courseRegistrationId
      passed: $passed
    ) {
      courseProgress {
        id
        passed
        examined
      }
    }
  }
`
